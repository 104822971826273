@font-face {
  font-family: 'HKGrotesk-Regular';
  src: local('HKGrotesk-Regular'), url(../fonts/HKGrotesk-Regular.otf) format('otf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

:root {
  --main-background-color: #FFFFFF;
  --main-text-color: #150a4b;
  --details-color: #ff5757;
  --details-secondary: #004aad;
  --bs-primary: #ff5757;
  --bs-body-font-family: "HKGrotesk-Regular" !important;
  font-family: "HKGrotesk-Regular" !important;
}

body {
  margin: 0;
  font-family: "HKGrotesk-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bs-body-bg);
  min-height: 100%;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-root {
  background-color: var(--main-background-color);
}

nav {
  background-color: white;
  height: 70px;
}

.nav-link {
  color: var(--main-text-color);
}

.nav-brand {
  background-color: #004aad;
  padding-right: 25px;
  color: white !important;
}

.nav-brand > a {
  color: white;
}

.logo-brand {
  height: 60px;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.navbar-footer {
  background-color: var(--details-color);
  min-width: 100%;
  min-height: 5px;
  display: block;
}

.carrousel-image {
  width: 100%;
  height: auto;
}

.line-download-guide {
  min-height: 400px;
}

.download-button {
  width: 100%;
}

.line-download-guide-text-parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.line-download-guide-text-parent > div {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.preview-image-container {
  background-color: var(--details-color);
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

.justified-text {
  text-align: justify;
}

.site-divider {
  height: 10px;
  width: 100%;
  background-color: var(--details-secondary);
}

.footer {
  min-height: 50px;
  background-color: var(--details-color);
  padding-top: 10px;
}

.footer > * {
  text-align: center;
  vertical-align: middle;
}

.container-list-group-items {
  padding-top: 20px;
  padding-bottom: 20px;
}
